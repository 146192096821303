import getData from "@/__main__/get-data.mjs";
import fetchReactions from "@/game-fortnite/fetches/reactions.mjs";
import validateFn from "@/game-fortnite/models/item-shop.mjs";
import * as API from "@/game-fortnite/utils/api.mjs";

async function fetchData() {
  const itemShop = await getData(API.getItemShop(), validateFn, [
    "fortnite",
    "itemShop",
  ]);
  const ids = itemShop?.entries
    ?.map((entry) => {
      if (!entry.brItems) return "";
      return [entry.offerId, entry.brItems?.map((item) => item.id)];
    })
    ?.filter((d) => Boolean(d))
    ?.flat(2);
  if (ids?.length === 0) return;

  return fetchReactions(ids);
}

export default fetchData;
